import { AVAIL_REQUEST, AVAIL_SUCCESS, AVAIL_FAIL } from "../constants/availWarrantyConst";

const availWarrantyReducer = (state = { productDetail: null }, action) => {
    switch (action.type) {
        case AVAIL_REQUEST:
            return {
                ...state,
                productDetail: null,
                warrantyLoading: true
            }

        case AVAIL_SUCCESS:
            return {
                ...state,
                productDetail: action.payload,
                warrantyLoading: false
            }

        case AVAIL_FAIL:
            return {
                ...state,
                productDetail: null,
                warrantyLoading: false
            }


        default:
            return {
                ...state,
            }
    }
}

export default availWarrantyReducer;