import { REQUEST_CUSTOMER_PRODUCTS, SUCCESS_CUSTOMER_PRODUCTS } from "../constants/userConstants";
import axios from "../apiConfig/api";

export const getCustomerProducts = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        // // consol.log(token , "customerProducts")
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        const { data } = await axios.post("/customerProducts", { token }, config)
        dispatch({type:SUCCESS_CUSTOMER_PRODUCTS, payload:data});
    } catch (error) {
        // // consol.log(error);
    }
}