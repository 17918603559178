import React from 'react'
import './maxcard1.scss'


const MaxCard = (props) => {


    return (
        <>
            <div className='cardCon1'>
                <div className='cardBox1' style={{ color: props.color1 }}>
                    <img className='img1' src={props.img} alt="" />

                    <div className='name1'> {props.data.name} </div>

                    <div className='phone1'>
                        {props.data.mobile1} <br />
                        {props.data.mobile2}
                    </div>

                    <div className='email1'>
                        {props.data.email} <br />
                        {props.data.web}
                    </div>

                    <div className='address1'>
                       {props.data.address}
                    </div>

                    <div style={{ color: props.color2 }}>

                        <div className='logo1'><img src={props.data.logo} alt="" /></div>

                        <div className='shop1'>
                            {props.data.business} <br />
                           <span> Best Electronics Shop </span>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default MaxCard