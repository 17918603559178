import { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAIL, OTP_VERIFICATION, LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL } from "../constants/userConstants";
import axios from "../apiConfig/api";


export const generateOtp = (number) => async (dispatch) => {
    try {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        const { data } = await axios.post('/otp', { phone_number: number }, config);
        // // consol.log(data);
        
    } catch (error) {
        // // consol.log(error);
        return Promise.reject(error.response.data.error)
    }
}
export const resendUserOtp = (number) => async (dispatch) => {
    try {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        const { data } = await axios.post('/resend_otp', { phone_number: number }, config);
        // // consol.log(data);
    } catch (error) {
        // consol.log(error?.response?.data?.message);
        // return Promise.reject(error?.response?.data?.message)
        return Promise.reject(error.response.data.error)
    }
}



export const loginUser = (userData) => async (dispatch) => {
    try {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        const { data } = await axios.post('/verify', { phone_number: userData.number, otp: userData.otp }, config);
        localStorage.setItem( "accessToken" , data.accessToken)
        // // consol.log(data , "login user");
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        return data;

        // if (data.status) {
        //     dispatch({ type: LOGIN_SUCCESS, payload: data });
        //     localStorage.setItem("accessToken", data.accessToken);
        // } else {
        //     dispatch({ type: LOGIN_SUCCESS, payload: { user: { phone_number: userData.number }, accessToken: null } });
        // }
        // dispatch({ type: OTP_VERIFICATION, payload: data.status });

    } catch (error) {
       
        return Promise.reject(error.response.data.error)
    }
}


export const loadUser = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        if (token) {
            const res = await axios.post('/customerSeller_token', { token: token }, config);
            dispatch({ type: LOAD_SUCCESS, payload: res.data });
        } else {
            // // consol.log('token not pressent');
        }
    } catch (error) {
        localStorage.removeItem("accessToken");
        // // consol.log(error);
    }
}



export const logoutUser = (navigate) => async (dispatch) => {
    try {
        // // consol.log("user logout");
        localStorage.removeItem("accessToken");
        navigate("/login");
    } catch (error) {
        // // consol.log(error);
    }
}



export const registerUser = (userData, role) => async (dispatch) => {
    try {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        if (role == "customer") {

            const { data } = await axios.post('/register_customer', userData, config);
            // // consol.log(data);
            dispatch({ type: LOGIN_SUCCESS, payload: data });
            localStorage.setItem("accessToken", data.accessToken);

        } else if (role == "seller") {
            const { data } = await axios.post('/register_seller', userData, config);
            // // consol.log(data);
            dispatch({ type: LOGIN_SUCCESS, payload: data });
            localStorage.setItem("accessToken", data.accessToken);
        }
    } catch (error) {
        // // consol.log(error);
    }
}

