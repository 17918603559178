import React from 'react'
import './maxcard2.scss'


const MaxCard = (props) => {
    return (
        <>
            <div className='cardCon2'>
                <div className='cardBox2'>
                    <img className='img2' src={props.img} alt="" />

                    <div className='logo2' style={{ color: props.color1 }}> <img src={props.data.logo} alt="" /> </div>

                    <div className='shop2' style={{ color: props.color2 }}> {props.data.business} </div>

                    <div style={{ color: props.color3 }}>
                        <div className='name2'> {props.data.name} </div>

                        <div className='phone2'> {props.data.mobile1} | {props.data.mobile2}</div>

                        <div className='email2'> {props.data.email} </div>

                        <div className='web'> {props.data.web} </div>

                        <div className='address2'>
                            {props.data.address}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MaxCard