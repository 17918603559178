import React, { useEffect, useState } from 'react'
import '../../login/login.scss'
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Controller, set, useForm } from "react-hook-form";
import { generateOtp, loginUser, resendUserOtp } from '../../../actions/userAction';
import toast from 'react-hot-toast';

const GenerateOtp = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch()

    const [login, setlogin] = useState(false);
    const navigate = useNavigate();

    const [time, setTime] = useState(60);

    const [state, setstate] = useState(false);
    const [read, setRead] = useState(true);
    const [disable, setDisable] = useState(true);

    const location = useLocation();
    const phone = location.state?.phone || ''; 
    // // consol.log(phone, "phone")
    const formData = location.state?.formData ;

    // // consol.log('formData in GenerateOtpPage:', formData);

   

    const [userData, setUserData] = useState({
        number: phone,
        otp: "",
    });

    useEffect(() => {
        if (state) {
            const update = () => {
                setTime((pre) => {
                    return pre > 0 ? pre -= 1 : 0;
                });
            }
            setInterval(update, 1000);
        }
    }, [state]);


    useEffect(() => {
        var otp = String(userData.otp)
        if (otp.length == 4) setDisable(false);
        else setDisable(true);
    }, [userData.otp]);


    const [number, setNumber] = useState('');
    const [isValid, setIsValid] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value });
       
    }
    
    



    ///////////////////////------------------Authentication----------------////////////////////
    const { user, otpStatus } = useSelector(state => state.userReducer);


    useEffect(() => {
        if (otpStatus) {
        //    // consol.log(otpStatus , "status")
        }
    }, [otpStatus]);



    //---------------------------------------------------- Resend otp -----------------------------//

    const resendOTP = (e) => {
        e.preventDefault();
        setTime(60);
        dispatch(resendUserOtp(userData.number));
    }

    const navigating = (e) => {
        e.preventDefault();
       
    }



    return (
        <>
            <div className='main'>
                <div className='loginContainer'>
                    <div className='heading'>Onepoket</div>
                    <form>
                    <p>Number</p>
                        <span className='icon'><CallIcon htmlColor='gray' /></span>
                        <input className="form-control" type="text" readOnly placeholder="Enter Your Number" name="number" value={userData.number} onChange={handleChange} />
                        <p>OTP</p>
                        <span className='icon'><LockOpenIcon htmlColor='gray' /></span>
                        <input className="form-control" type="text"  placeholder="Enter OTP" name="otp" value={userData.otp} onChange={handleChange} />

                        {!read && <div className='resendClass'> {time == 0 ? <span className='enable' onClick={resendOTP}>Resend OTP</span> : <span className='disable'>Resend OTP</span>} in {time} sec</div>}

                        
                         <button type='submit' id='subBtn' className='btn btn-primary' onClick={navigating}>Login</button>
                        
                    </form>
                </div>
            </div>
        </>
    )
}

export default GenerateOtp