import { LOGIN_SUCCESS, LOGIN_REQUEST, OTP_VERIFICATION, LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL } from "../constants/userConstants";


const userReducer = (state = { user: null, accessToken: null }, action) => {
    switch (action.type) {

        case LOGIN_REQUEST:
        case LOAD_REQUEST:
            return {
                ...state,
                user:null,
                accessToken: null
            }


        case LOGIN_SUCCESS:
            return{
                ...state,
                user:action.payload
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                accessToken: action.payload.accessToken
            }


        case LOGOUT_SUCCESS:
            return {
                user: null,
            }

        case OTP_VERIFICATION:
            return {
                ...state,
                otpStatus: { status: action.payload }
            }


        default:
            return state;
    }
}

export default userReducer;