import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import demo from '../../assets/demo.jpg'

const Navbar2 = () => {
    const closeBar = () => {
        const l = document.getElementById('con');
        l.style.display = 'none';

        const bar = document.getElementById('slide');
        bar.style.left = '-330px';
    }


    /////////////////////////-----------Redux----------------/////////////////////////
    const { user } = useSelector(state => state.userReducer);
    const [profileData, setProfileData] = useState({
        shop_name: "",
        img: demo
    });

    useEffect(() => {
        if (user) {
            setProfileData({ shop_name: user.shop_name, img: user.avatarUrl });
        }
    }, [user]);





    return (
        <>
            <div className='navCon' id='con' onClick={closeBar}></div>
            <div className='sidebar' id='slide' onClick={e => e.stopPropagation()}>

                <Link to="/profile"><img src={profileData.img} alt="" /></Link>
                <p> {profileData.shop_name} </p>

                <div><Link to="/ecard" id='link'>My E-card</Link></div>
                <hr />
                <div><Link to="/sales" id='link'>My Sales</Link></div>
                <hr />
                <div><Link to='/order' id='link'>Place Order</Link></div>
                <hr />
                <div><Link to='/history' id='link'>Order History</Link></div>
                <hr />
                <div><Link to='/' id='link'>Switch To User</Link></div>
                <hr />
                <div><Link to='/add_customer' id='link'>Generate Warranty</Link></div>
                <hr />
                <div><Link to='/login' id='link'>Logout</Link></div>

            </div>
        </>
    )
}

export default Navbar2