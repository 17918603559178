import React, { useEffect } from 'react'
import './registerForm.scss'
import ClearIcon from '@mui/icons-material/Clear';
import BackupIcon from '@mui/icons-material/Backup';
import { Button, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from '../../../apiConfig/api';
import { useDispatch, useSelector } from 'react-redux';
import { getProductData, getPurchaseForm } from '../../../actions/availWarrantyAction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useForm } from 'react-hook-form';
import { Bars } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { generateOtp, resendUserOtp } from '../../../actions/userAction';
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import logo from "../../../assets/onepoket_logo.png"



const RegisterForm = () => {




    const navigate = useNavigate();
    // const [state, setstate] = useState(false);
    const { id, alphanumeric } = useParams();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onTouched" });

    ////////////////// redux ////////////////////////////////
    const dispatch = useDispatch();
    const { productDetail } = useSelector(state => state.availWarrantyReducer);
    useEffect(() => {
        if (!productDetail) {
            dispatch(getProductData(alphanumeric));
        }
    }, []);

    useEffect(() => {
        if (productDetail) {
            // // consol.log(productDetail, "prodcut details")
        }
    }, [productDetail])

    const [state, setState] = useState({
        name: "",
        phone_number: "",
        email: "",
        purchased_from: "",
        invoice: "",
        product_id: id,
        alphanumeric: alphanumeric,
        source: "",
        IP_city: "",
        IP_state: "",
        IP_country: "",
        longitude: "",
        latitude: "",
        otp: ""
    });
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        setErrNumber("")
    }

    // const imageChange = (e) => {
    //     setState({ ...state, "invoice": e.target.files[0] });
    // }
    const imageChange = (e) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
                setState({ ...state, "invoice": selectedFile });
            } else {
                toast.error("Please select a valid file with one of the following extensions: JPG, JPEG, PNG, or PDF.")
                // You can also reset the file input if needed
                e.target.value = null;
            }
        }
    }



    const [openBox, setOpenBox] = useState(false);
    const [other, setOther] = useState(false);
    const [purchased_from_name, set_purchased_from_name] = useState("");
    const [purchaseData, setPurchaseData] = useState("");

    const purchase = (val) => {
        setOpenBox(false);
        if (val == "Other") {
            setOther(true);
        } else {
            setState({ ...state, "purchased_from": val });
            setOther(false);
            set_purchased_from_name("");
        }
        setPurchaseData(val)
    }

    const [showModal, setShowModal] = useState(false);

    const handlePurchaseFrom = (e) => {
        set_purchased_from_name(e.target.value);
        setState({ ...state, "purchased_from": `Other: ${e.target.value}` })
    }


    ////////////////////////////////---------------- puchase array --------------////////////////////////////
    const { purchaseArray } = useSelector(state => state.puchaseFromReducer);

    const [productPurchase, setProductPurchase] = useState([]);

    useEffect(() => {
        if (!purchaseArray) {
            dispatch(getPurchaseForm(alphanumeric));
        }
    }, []);

    useEffect(() => {
        if (purchaseArray) {
            setProductPurchase(purchaseArray);
        }
    }, [purchaseArray])


    ///////////////////////////////////// get IP Data /////////////////////////////////
    const getIPData = async () => {
        try {

            const options = {
                method: 'GET',
                url: 'https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation',
                params: { apikey: '873dbe322aea47f89dcf729dcc8f60e8' },
                headers: {
                    'X-RapidAPI-Key': '8fa76c397cmshef6abe11fba5ac4p1c96f2jsn2ec184c7d370',
                    'X-RapidAPI-Host': 'find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com'
                }
            };

            const { data } = await axios.request(options);
            // // consol.log(data);
            setState({ ...state, "source": data.ip, "IP_city": data.city, "IP_state": data.state, "IP_country": data.country, "latitude": data.latitude, "longitude": data.longitude })

        } catch (error) {
            // // consol.log(error);
        }
    }

    useEffect(() => {
        getIPData();
    }, [])



    useEffect(() => {
        setTimeout(() => {
            document.getElementById("inner").style.bottom = "0px";
        }, 10);
    }, [])

    const [errNumber, setErrNumber] = useState("")


    const validateForm = () => {

        let isValid = true;
        if (state.phone_number.length !== 10) {
            setErrNumber("Please enter 10 digit mobile number without “0” or “+91” ");

            isValid = false;
        } else {

            setErrNumber("");
        }







        // Add more validation checks for other fields as needed

        return isValid;
    };

    const [errTime, setErrTime] = useState("")
    const sendOtp = async () => {

        try {
            const token = localStorage.getItem("accessToken");
            const config = { headers: { 'Content-Type': 'application/json' } };
            setLoader(true)
            const { data } = await axios.post(`/resend_registration_otp`, {
                phone_number: state.phone_number
            }, config);

            setLoader(false)


        } catch (error) {
            // // consol.log(error);
            toast.error(error.response.data.error)
            // setShowModal(false)
            setErrTime(error.response.data.error)
            setLoader(false)
        }
    }

    const sendOtpRegistration = async () => {

        try {
            const token = localStorage.getItem("admin_token");
            const config = { headers: { 'Content-Type': 'application/json' } };
            setLoader(true)
            const { data } = await axios.post(`/send_registration_otp`, {
                phone_number: state.phone_number
            }, config);

            setLoader(false)


        } catch (error) {
            // // consol.log(error);
            toast.error(error.response.data.error)
            setShowModal(false)
            setLoader(false)
        }
    }
    const [dataInstall, setDataInstall] = useState([])

    // const getInstallationData = async () => {

    //   try {
    //     const token = localStorage.getItem("accessToken");
    //     const config = { headers: { 'Content-Type': 'application/json' } };

    //     const { data } = await axios.post(`/customer/get_installation_data`, {
    //       token: token, alphanumeric: alphanumeric
    //     }, config);
    //     // consol.log(data?.is_form_submitted, "data")
    //     setDataInstall(data?.is_form_submitted)



    //   } catch (error) {
    //     // consol.log(error);

    //   }
    // }
    // useEffect(()=>{
    //    getInstallationData()
    // },[])



    const [otpVerified, setOtpVerified] = useState(false);

    const onSubmit = () => {

        const isFormValid = validateForm();

        if (!isFormValid) {
            return; // Don't proceed with the API call if validation fails
        }
        // // consol.log(state);
        setLoader(true);

        if (!(state.product_id && state.phone_number && state.name && state.email && state.purchased_from && state.invoice)) {
            window.alert("invalid details")
        } else {

            if (productDetail?.use_otp == 0) {
                setLoader(true)
                // setShowModal(true)
                // dispatch(generateOtp(state.phone_number))
                // setRead(false)
                // setStateTime(true)
                // sendOtp()
                activate()

                // navigate(`/installation_form/${alphanumeric}`)

                setLoader(false)
            }
            if (productDetail?.use_otp == 1) {
                setLoader(true)
                setShowModal(true)
                sendOtpRegistration()
                setRead(false)
                setStateTime(true)
                setLoader(false)
            }
            // if(productDetail?.use_otp == 1 && productDetail?.use_mock_otp == 1){
            //     setLoader(true)
            //     setShowModal(true)
            //     dispatch(generateOtp(state.phone_number))
            //     setRead(false)
            //     setStateTime(true)
            //     setLoader(false)
            // }

            setLoader(false)



        }
    }

    const activate = () => {
        setLoader(true)
        const formdata = new FormData();
        formdata.append("name", state.name);
        formdata.append("alphanumeric", state.alphanumeric);
        formdata.append("email", state.email);
        formdata.append("phone_number", state.phone_number);
        formdata.append("purchased_from", state.purchased_from);
        formdata.append("invoice", state.invoice);
        formdata.append("product_id", state.product_id);
        formdata.append("source", state.source);
        formdata.append("IP_city", state.IP_city);
        formdata.append("IP_state", state.IP_state);
        formdata.append("IP_country", state.IP_country);
        formdata.append("latitude", state.latitude);
        formdata.append("longitude", state.longitude);
        formdata.append('otp', state.otp)
        registerWarranty(formdata)
        setLoader(false)


    }


    const [loader, setLoader] = useState(false);


    const registerWarranty = async (formdata) => {
        try {
            // // consol.log(formdata);
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            setLoader(true)

            const { data } = await axios.post('/avail_warranty', formdata, config);
            // // consol.log(data, "avail warranty")
            if (data.accessToken) {
                localStorage.setItem("accessToken", data.accessToken);
                // if (data.review_url) {
                //     window.open(data.review_url, "_blank");
                // }

                if (data?.use_installation_form == 1 && data?.is_installation_required == 1) {
                    navigate(`/installation_form/${alphanumeric}`, {
                        state: { reviewUrl: data.review_url }
                    });



                } else {
                    if (data.review_url) {
                        window.open(data.review_url, "_blank");
                    }
                    navigate(`/`)

                }
                // navigate(`/installation_form/${alphanumeric}`);
                // navigate('/')
            }
        } catch (error) {
            // consol.log(error, "errorrr")

                // // consol.log(error?.response?.data?.error, "error");
                setLoader(false);
                toast.error(error?.response?.data?.error)
            
        }
    }
    const gernerate = (e) => {
        e.preventDefault();
        const isFormValid = validateForm();

        if (!isFormValid) {
            return; // Don't proceed with the API call if validation fails
        }
        // dispatch(generateOtp(userData.number));
        setRead(false);
        // setstate(true);
        setlogin(true);
    }


    const navigating = (e) => {
        e.preventDefault();

    }


    const [login, setlogin] = useState(false);


    const [time, setTime] = useState(60);
    const [stateTime, setStateTime] = useState(false)
    const [read, setRead] = useState(true);
    const [disable, setDisable] = useState(true)

    useEffect(() => {
        if (stateTime) {
            const update = () => {
                setTime((pre) => {
                    return pre > 0 ? pre -= 1 : 0;
                });
            }
            setInterval(update, 1000);
        }
    }, [stateTime]);


    //---------------------------------------------------- Resend otp -----------------------------//

    const resendOTP = (e) => {
        // alert("hello")
        e.preventDefault();
        setTime(60);
        sendOtp()
    }

    const numberInputOnWheelPreventChange = (e) => {

        e.target.blur()


        e.stopPropagation()



        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const numberInputOnKeyDown = (e) => {
        // Allow only digits (0-9), Backspace, Delete, and Tab keys
        const allowedKeys = ['Backspace', 'Delete', 'Tab'];
        const isDigit = /\d/.test(e.key);
      
        if (!(isDigit || allowedKeys.includes(e.key))) {
          e.preventDefault();
        }
      };
      




    return (
        <>
            <div className='main'>
                <div className='registerForm' onClick={() => setOpenBox(false)}>


                    {/* ////////--------------------------- Loader ----------------------------////////// */}
                    {loader && <div className='warrantyLoader'>
                        <Bars
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>}
                    {/* ////////--------------------------- Loader ----------------------------////////// */}


                    <div id='inner'>

                        <div id="line"></div>

                        <div className='innerScroll'>

                            <div>

                                <button className='btn but1' onClick={() => navigate(-1)}><ClearIcon /></button>

                                <div className='head'>
                                    <div>Activate Warranty</div>
                                    <section>
                                        {productDetail?.product_name} <br />
                                        Model No: {productDetail?.product_model}
                                    </section>
                                </div>


                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="form-group">
                                        <label>Name <span>*</span></label>
                                        <input type="text" className="form-control" name="name" value={state.name} placeholder='Enter Your Name' {...register("name", { onChange: (e) => { handleChange(e) }, required: "Name is required!" })} />
                                        <span>{errors.name?.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label>Phone <span>*</span></label>
                                        <input type="number" className="form-control" name="phone_number" value={state.phone_number} placeholder='Enter Your Phone Number' {...register("phone_number", { onChange: (e) => { handleChange(e) }, required: "Phone Number is required! and must be 10 digit " })}
                                        onWheel={numberInputOnWheelPreventChange}
                                        onKeyDown={numberInputOnKeyDown}

                                        />
                                        <span>{errors.phone_number?.message}</span>
                                        <div className='text-red-500' style={{ color: "red", marginTop: "3px" }}>{errNumber}</div>
                                    </div>

                                    <div className="form-group">
                                        <label>Email <span>*</span></label>
                                        <input style={{ textTransform: "none" }} type="email" className="form-control" name="email" value={state.email} placeholder='Enter Your Email' {...register("email", { onChange: (e) => { handleChange(e) }, required: "Email is required!" })} />
                                        <span>{errors.email?.message}</span>
                                    </div>

                                    <div className="form-group" onClick={(e) => e.stopPropagation()}>
                                        <label>Purchased From <span>*</span></label>
                                        <div className='boxVal form-control' onClick={() => setOpenBox(!openBox)}>{purchaseData}</div>
                                        <ArrowDropDownIcon className='arrowDown' fontSize='large' />

                                        {openBox ? <div className='selectBox'>
                                            {productPurchase.map((val, index) => {
                                                return (
                                                    <Button key={index} variant='outlined' className='but' onClick={() => purchase(val.title)}> {val.title} </Button>
                                                );
                                            })}
                                        </div> : null}

                                    </div>

                                    {other ? <div className="form-group">
                                        <input style={{ textTransform: "none" }} type="text" className="form-control" name="purchased_from_name" value={purchased_from_name} placeholder='Enter the store name' {...register("purchased_from_name", { onChange: (e) => { handlePurchaseFrom(e) }, required: "Purchase from is required!" })} />
                                        <span>{errors.purchased_from_name?.message}</span>
                                    </div> : null}


                                    <div className="form-group">
                                        <label>Upload Invoice <span>*</span></label>
                                        <div className='invoiceBox'>
                                            <div><BackupIcon /></div>
                                            <input type="file" name="" id="" onChange={imageChange}
                                                accept='.jpg , .png , .pdf , .jpeg' />
                                            {!state.invoice ? <section className='section1'>Drop / Click here to upload</section> :
                                                <section className='section2'> {state.invoice?.name} </section>}
                                            <span>Only JPG / PNG / PDF file upto 2Mb</span>
                                        </div>
                                    </div>



                                    <div className='genBox'> <Button className='generate' variant='contained' type='submit'>Activate Warranty</Button> </div>
                                    {showModal && (
                                        <div className='modal'>

                                            <div className='modal-content'>
                                                {/* <button className='close-modal' onClick={() => setShowModal(false)}>
                                                    <ClearIcon />
                                                </button> */}
                                                <div className='mt-2 text-center w-[20%] mx-auto'>
                                                    <img src={logo} className='w-[50px] h-[50px]' />
                                                </div>
                                                <form>
                                                    <div className='mt-3'>

                                                        <div>
                                                            <h3 className='text-[16px] leading-[24px]'>Please enter 4 digit OTP
                                                                sent on <span className='font-semibold '>{state.phone_number}</span></h3>
                                                            <h3 className='text-[#0065FF] text-[14px] mt-2 cursor-pointer' onClick={() => setShowModal(false)}>Change mobile number</h3>
                                                        </div>
                                                    </div>

                                                    <p>Enter OTP</p>
                                                    <span className='icon'><LockOpenIcon htmlColor='gray' /></span>
                                                    <input className="form-control" type="text" readOnly={read} placeholder="Enter OTP" name="otp" value={state.otp} onChange={handleChange} disabled={errTime} />

                                                    {errTime ? (<div className='text-red-500'>{errTime}</div>) : (<div> {!read && (
                                                        <div className='resendClass'>
                                                            {time === 0 ? (
                                                                <span className='enable' onClick={resendOTP}>Resend OTP</span>
                                                            ) : (
                                                                <span className='disable'>Resend OTP</span>
                                                            )}
                                                            in {time} sec
                                                        </div>
                                                    )}</div>)}





                                                    {errTime ? null : <button type='button' id='subBtn' className='btn btn-primary' onClick={activate}>Confirm OTP</button>}

                                                </form>
                                                {/* Add your modal content here */}
                                            </div>
                                        </div>
                                    )}


                                </form>


                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default RegisterForm