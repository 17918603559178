import React, { useEffect, useState } from 'react'
import './login.scss'
import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Controller, set, useForm } from "react-hook-form";
import { generateOtp, loginUser, resendUserOtp } from '../../actions/userAction';
import toast from 'react-hot-toast';

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [login, setlogin] = useState(false);
    const navigate = useNavigate();

    const [time, setTime] = useState(60);

    const [state, setstate] = useState(false);
    const [read, setRead] = useState(true);
    const [disable, setDisable] = useState(true);

    const [userData, setUserData] = useState({
        number: "",
        otp: "",
    });

    useEffect(() => {
        if (state) {
            const update = () => {
                setTime((pre) => {
                    return pre > 0 ? pre -= 1 : 0;
                });
            }
            setInterval(update, 1000);
        }
    }, [state]);


    useEffect(() => {
        var otp = String(userData.otp)
        if (otp.length == 4) setDisable(false);
        else setDisable(true);
    }, [userData.otp]);


    const [number, setNumber] = useState('');
    const [isValid, setIsValid] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value });
        setErrNumber("")

    }





    ///////////////////////------------------Authentication----------------////////////////////
    const { user, otpStatus } = useSelector(state => state.userReducer);
    const token = localStorage.getItem("accessToken");
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        // // consol.log(user, "role")
        setTimeout(() => {
            if (token) {
                if (user?.role == "customer") {
                    // alert(user?.role)
                    // // consol.log("CUSTOMER")
                    navigate('/');
                } else if (user?.role == "seller") {
                    navigate('/dashboard');
                }
            }
        }, 100);
    }, [user]);

    const [errNumber, setErrNumber] = useState("")
    const [showOTPInput, setShowOTPInput] = useState(false);

    const validateForm = () => {

        let isValid = true;
        if (userData.number.length !== 10) {
            setErrNumber("Please enter 10 digit mobile number without “0” or “+91” ");
            setShowOTPInput(false);
            isValid = false;
        } else {
            setShowOTPInput(true);
            setErrNumber("");
        }








        return isValid;
    };

    /////////////////////////////----------------------Redux-----------------------/////////////////////
    const dispatch = useDispatch();

    const gernerate = (e) => {
        e.preventDefault();
        const isFormValid = validateForm();

        if (!isFormValid) {
            return; // Don't proceed with the API call if validation fails
        }
        dispatch(generateOtp(userData.number)).then((data)=>{

        }).catch((error)=>{
            setErrTimeStatus(error)
        })
        setRead(false);
        setstate(true);
        setlogin(true);
    }


    const navigating = (e) => {
        e.preventDefault();
        dispatch(loginUser(userData)).then((data) => {
            // // consol.log("hello ", data)
            if (data.accessToken) {
                if (data?.user?.role == "customer") {
                    // alert(data?.user?.role)
                    // // consol.log("CUSTOMER")
                    navigate('/');
                } else if (data?.user?.role == "seller") {
                    navigate('/dashboard');
                }
            }

        })
            .catch((err) => {
                // // consol.log(err)
                toast.error(err)
            })
    }

    useEffect(() => {
        if (otpStatus) {
            navigate('/');
        }
    }, [otpStatus]);



    //---------------------------------------------------- Resend otp -----------------------------//
    const [errTimeStatus, setErrTimeStatus] = useState("")
    const resendOTP = (e) => {
        e.preventDefault();
        setTime(60);
        dispatch(resendUserOtp(userData.number)).then((data) => {
            // // consol.log(data)
        }).catch((error) => {
            // // consol.log(error, "errr")
            // toast.error(error)
            setErrTimeStatus(error)
        })
    }

    // // consol.log(errTimeStatus, "errtime")



    return (
        <>
            <div className='main'>
                <div className='loginContainer'>
                    <div className='heading '>Onepoket</div>
                    <form>
                        <p className=''>Number</p>
                        <span className='icon'><CallIcon htmlColor='gray' /></span>
                        <input className="form-control" type="text" placeholder="Enter Your Number" name="number" value={userData.number} onChange={handleChange} />
                        <div className='text-red-500' style={{ color: "red", marginTop: "3px" }}>{errNumber}</div>

                        <p>OTP</p>
                        <span className='icon'><LockOpenIcon htmlColor='gray' /></span>
                        <input className="form-control" type="text" readOnly={read} placeholder="Enter OTP" name="otp" value={userData.otp} onChange={handleChange} disabled = {errTimeStatus} />

                        {errTimeStatus ? <div className='text-red-500 mt-4'>{errTimeStatus}</div> : (<div>
                            {!read && <div className='resendClass'> {time == 0 ? <span className='enable' onClick={resendOTP}>Resend OTP</span> : <span className='disable'>Resend OTP</span>} in {time} sec</div>}

                            {!login ?
                                <button type='submit' id='subBtn' className='btn btn-primary' onClick={gernerate}>Generate OTP</button>
                                : <button type='submit' id='subBtn' className='btn btn-primary' onClick={navigating}>Login</button>
                            }
                        </div>)}


                    </form>
                </div>
            </div>
        </>
    )
}

export default Login