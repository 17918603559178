import { PURCHASE_FROM_SUCCESS } from "../constants/availWarrantyConst";

const puchaseFromReducer = (state = { purchaseArray: null }, action) => {
    switch (action.type) {

        case PURCHASE_FROM_SUCCESS:
            return {
                ...state,
                purchaseArray: action.payload
            }

        default:
            return {
                ...state,
            }
    }
}

export default puchaseFromReducer;