import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './navbar.scss'

import demo from '../../assets/demo.jpg';
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../actions/userAction';

const Navbar = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();


    ///////////------------- REDUX ------------ ///////////////

    const { user } = useSelector(state => state.userReducer);
    const [userData, setUserData] = useState({
        name: "",
        avatarUrl: demo
    });

    useEffect(() => {
        if (user) {
            setUserData({ name: user.name, avatarUrl: user.avatarUrl ? user.avatarUrl : demo });
        }
    }, [user]);


    ///////////------------- REDUX ------------ ///////////////


    const closeBar = () => {
        const l = document.getElementById('con');
        l.style.display = 'none';

        const bar = document.getElementById('slide');
        bar.style.left = '-330px';
    }



    const logout = () => {
        dispatch(logoutUser(navigate));
    }



    return (
        <>
            <div className='navCon' id='con' onClick={closeBar}></div>
            <div className='sidebar' id='slide' onClick={e => e.stopPropagation()}>

                {/* <Link to="/user_profile"><img src={userData.avatarUrl} alt="" /></Link>
                <p> {userData.name} </p> */}

                <div><Link to="/user_profile" id='link'>My Profile</Link></div>
                <hr />

                {/* <div><Link to="/" id='link'>Product</Link></div> */}
                {/* <hr /> */}


                {/* <div><Link to='/add_invoice' id='link'>Add Invoice</Link></div> */}

                {/* <div><Link to='/add_warranty' id='link'>Add Warranty</Link></div>
                <hr /> */}

                <div><Link to='/support' id='link'>Support</Link></div>
                <hr />

                {/* <div><Link to='/service_center' id='link'>Service Center</Link></div> */}
                {/* <hr /> */}

                {/* <div><Link to='/dashBoard' id='link'>Switch To Seller</Link></div>
                <hr /> */}

                <div onClick={logout}>Logout</div>
            </div>
        </>
    )
}

export default Navbar