import React, { useEffect } from 'react'
import './userDetails.scss'
import { useNavigate } from 'react-router-dom';

import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import check from '../../assets/check1.gif'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../actions/userAction';

const UserDetails = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();


  /////////////////////////////////////////////////////////////////////////////
  const { user, accessToken } = useSelector(state => state.userReducer);
  ////////////////////////////////////////////////////////////////////////////


  const [userData, setUserData] = useState({
    name: "",
    email: "",
    avatarUrl: "",
    phone_number: "",
    date_of_birth: ""
  });

  useEffect(() => {
    if (user) {
      setUserData({ ...userData, ["phone_number"]: user.phone_number });
    }
  }, [user]);

  /////////////////////////////////------------ Authentication -----------------///////////////////
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setTimeout(() => {
      if (token && user) {
        if (user?.role == "customer") {
          navigate('/');
        } else if (user?.role == "seller") {
          navigate('/dashboard');
        }
      } else if (!user) {
        navigate('/login');
      }
    }, 100);
  }, [user])
  /////////////////////////////////------------ Authentication -----------------///////////////////


  const [avatar, setAvatar] = useState("");

  var reader = new FileReader();
  const handleImageChange = (e) => {
    const imgUrl = e.target.files[0];
    setUserData({ ...userData, ["avatarUrl"]: imgUrl });
    reader.readAsDataURL(imgUrl);
  }
  reader.onload = () => {
    setAvatar(reader.result);
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }



  //////////////////////////////////-------------------------- Redux ----------------------////////////////////
  const dispatch = useDispatch();

  const formdata = new FormData();

  const onSubmit = () => {

    // // consol.log(userData);

    formdata.append('name', userData.name);
    formdata.append('avatarUrl', userData.avatarUrl);
    formdata.append('email', userData.email);
    formdata.append('date_of_birth', userData.date_of_birth);
    formdata.append('phone_number', userData.phone_number);

    dispatch(registerUser(formdata, "customer"));

    // setShow(true);
    // setTimeout(() => navigate('/'), 2000);
  }




  return (
    <>
      <div className='main'>
        <div className='userDetails'>
          <div className='header'></div>

          <div className='profileCon'>
            <img src={avatar} alt="" />
          </div>


          <form onSubmit={handleSubmit(onSubmit)}>

            <div className='fileSection'>Add Profile Photo
              <input type="file" onChange={handleImageChange} />
            </div>

            <div>
              <span className='icon'><PersonIcon /></span>
              <input className="form-control" type="text" placeholder="Enter Your Name" name='name' value={userData.name}  {...register("name", { onChange: (e) => handleChange(e), required: "name is required!" })} />
              <p>{errors.name?.message}</p>
            </div>

            <div>
              <span className='icon'><MailOutlineIcon /></span>
              <input className="form-control" type="email" placeholder="Enter Your Email" name='email' value={userData.email} {...register("email", { onChange: (e) => handleChange(e), required: "email is required!", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address!" } })} />
              <p>{errors.email?.message}</p>
            </div>

            <button className='btn' type='submit'>Save</button>

          </form>

          {show ? <div className='gifCon'>
            <div>
              <img src={check} alt="" />
            </div>
          </div> : null}

        </div>
      </div>
    </>
  )
}

export default UserDetails