import React from 'react'
import './myprofile.scss'

import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import demo from '../../assets/demo.jpg';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import CakeIcon from '@material-ui/icons/Cake';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadUser } from '../../actions/userAction';


const MyProfile = () => {

    const [editImage, setImage] = useState(false);
    const [showSave, setSave] = useState(false);
    const [edit, setEdit] = useState(false);

    const saveData = () => {
        setImage(false);
        setSave(false);
        setEdit(false);
    }


    const [userData, setUserData] = useState({
        id: "",
        name: "",
        phone_number: "",
        role: "",
        email: "",
        avatarUrl: demo,
        date_of_birth: ""
    })

    const handleOnChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }


    /////////////////////////////// --------------------- REDUX ------------------ ///////////////////////
    const { user } = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    useEffect(() => {
        if (user) {
            setUserData({
                id: user.id ? user.id : "",
                role: user.role ? user.role : "",
                name: user.name ? user.name : "",
                phone_number: user.phone_number ? user.phone_number : "",
                email: user.email ? user.email : "",
                avatarUrl: user.avatarUrl ? user.avatarUrl : demo,
                date_of_birth: user.date_of_birth ? user.date_of_birth : ""
            });
        }
    }, [user]);


    return (
        <>
            <div className='main'>
                <div className='myprofile'>

                    <div className='header'>
                        <Link to="/" className='btn back'><ArrowBackIcon /></Link>
                        My Profile
                    </div>

                    {/* <div className='imgSection'>
                        <img src={userData.avatarUrl} alt="" />
                        <section> {userData.name} </section>

                        {editImage ? <div id='image'> <Button variant='contained' id='btt'>
                            <CameraAltIcon fontSize='small' />
                            <input type="file" name="" id="inp" />
                        </Button></div> : null}

                        <Button variant='contained' id='edit' onClick={() => { setImage(true); setSave(true); setEdit(true) }}>
                            <EditIcon fontSize='small' />
                        </Button>

                    </div> */}

                    <div className='spBox'>
                        <div className='sp1'>
                            <PersonPinIcon fontSize='small' />
                        </div>
                        <div className='sp2'>
                            <section>Name</section>
                            {!edit ? <div> {userData.name} </div> :
                                <input type="text" value={userData.name} name="name" onChange={handleOnChange} />}
                        </div>
                    </div>

                    {/* <div className='spBox'>
                        <div className='sp1'>
                            <CakeIcon fontSize='small' />
                        </div>
                        <div className='sp2'>
                            <section>DOB</section>
                            {!edit ? <div> {userData.date_of_birth} </div> :
                                <input type="text" value={userData.date_of_birth} name="date_of_birth" onChange={handleOnChange} />}
                        </div>
                    </div> */}

                    <div className='spBox'>
                        <div className='sp1'>
                            <PhoneIcon fontSize='small' />
                        </div>
                        <div className='sp2'>
                            <section>Mobile</section>
                            {!edit ? <div> {userData.phone_number ? `+91 ${userData.phone_number}` : ""} </div> :
                                <input type="text" value={userData.phone_number} name="phone_number" onChange={handleOnChange} />}

                        </div>
                    </div>

                    <div className='spBox'>
                        <div className='sp1'>
                            <MailOutlineIcon fontSize='small' />
                        </div>
                        <div className='sp2'>
                            <section>Email</section>
                            {!edit ? <div> {userData.email} </div> :
                                <input type="email" value={userData.email} name="email" onChange={handleOnChange} />}

                        </div>
                    </div>

                    {showSave ? <div className='save'>
                        <Button variant='contained' className='bt' onClick={saveData}>Save</Button>
                    </div> : null}

                </div>
            </div>
        </>
    )
}

export default MyProfile