export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOAD_SUCCESS = "LOAD_SUCCESS";
export const LOAD_FAIL = "LOAD_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";


export const OTP_VERIFICATION = "OTP_VERIFICATION";


////////////////// products ////////////////////////
export const REQUEST_CUSTOMER_PRODUCTS = "REQUEST_CUSTOMER_PRODUCTS"
export const SUCCESS_CUSTOMER_PRODUCTS = "SUCCESS_CUSTOMER_PRODUCTS"