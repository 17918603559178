import React, { useState, useEffect } from 'react'
import './register.scss'
import AddIcon from '@material-ui/icons/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../actions/userAction';


const Register = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();


    ///////////////////////////// ------------ state, city --------------------//////////////////////
    const [stateArray, setStateArray] = useState([
        { name: "Uttar Pradesh" },
        { name: "Madhya Pradesh" },
        { name: "Bihar" },
    ]);

    const [cityArray, setCityArray] = useState([
        { name: "Indore" },
        { name: "Bhopal" },
        { name: "Dewas" },
    ]);


    /////////////////////////////------------ image -----------------/////////////////////
    const [imageSelection, setImageSelection] = useState(false);
    const handleImageChange = (e) => {
        setImageSelection(true);
        setSellerData({ ...sellerData, ["avatarUrl"]: e.target.files[0] });
    }



    //////////////////--------------- Authentiaction----------------///////////////////////
    const { user } = useSelector(state => state.userReducer);
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        setTimeout(() => {
            if (token && user) {
                if (user?.role == "customer") {
                    navigate('/');
                } else if (user?.role == "seller") {
                    navigate('/dashboard');
                }
            } else if (!user) {
                navigate('/login');
            }
        }, 100);
    }, [user])

    //////////////////////////////////// ----------- Redux -----------////////////////////////

    const dispatch = useDispatch();
    const [sellerData, setSellerData] = useState({
        shop_name: "",
        owner_name: "",
        email: "",
        avatarUrl: "",
        state: "",
        city: "",
        phone_number: user?.phone_number,
        address: "",
        gst: "",
    });

    const handleOnChange = (e) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }

    const formdata = new FormData();
    const registration = () => {

        // // consol.log(sellerData);

        formdata.append("shop_name", sellerData.shop_name);
        formdata.append("owner_name", sellerData.owner_name);
        formdata.append("email", sellerData.email);
        formdata.append("phone_number", sellerData.phone_number);
        formdata.append("avatarUrl", sellerData.avatarUrl);
        formdata.append("address", sellerData.address);
        formdata.append("state", sellerData.state);
        formdata.append("city", sellerData.city);
        formdata.append("gst", sellerData.gst);

        dispatch(registerUser(formdata, "seller"));
        navigate('/dashboard');
    }

    return (
        <>
            <div className='main'>
                <div className='register'>
                    <div className='heading'>Invoicify <span>Bussiness</span></div>


                    <form onSubmit={handleSubmit(registration)}>
                        <div>
                            <p>Shop Name <span>*</span></p>
                            <input className="form-control" type="text" value={sellerData.shop_name} placeholder="Enter Your Shop Name" {...register("shop_name", { onChange: (e) => handleOnChange(e), required: "shop name is required!" })} />
                            <div className='alert'>{errors.shop_name?.message}</div>

                            <p>Owner Name <span>*</span></p>
                            <input className="form-control" type="text" value={sellerData.owner_name} placeholder="Enter Your Name" {...register("owner_name", { onChange: (e) => handleOnChange(e), required: "name is required!" })} />
                            <div className='alert'>{errors.owner_name?.message}</div>

                            <p>Gmail <span>*</span></p>
                            <input className="form-control" type="email" value={sellerData.email} placeholder="Enter Your Gmail" {...register("email", { onChange: (e) => handleOnChange(e), required: "email is required!", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address!" } })} />
                            <div className='alert'>{errors.email?.message}</div>

                            <p>GST <span>*</span></p>
                            <input className="form-control" type="text" value={sellerData.gst} placeholder="Enter Your GST Tin" {...register("gst", { onChange: (e) => handleOnChange(e), required: "GST Tin is required!" })} />
                            <div className='alert'>{errors.gst?.message}</div>

                            <p>Address <span>*</span></p>
                            <input className="form-control" type="text" value={sellerData.address} placeholder="Enter Your Address" {...register("address", { onChange: (e) => handleOnChange(e), required: "address is required!" })} />
                            <div className='alert'>{errors.address?.message}</div>

                            <select name="state" id="s1" onChange={handleOnChange}>
                                <option value="">Select State</option>
                                {stateArray.map((val, index) => {
                                    return (
                                        <option value={val.name} key={index}> {val.name} </option>
                                    );
                                })}
                            </select>

                            <select name="city" id="s2" onChange={handleOnChange}>
                                <option value="">Select City</option>
                                {cityArray.map((val, index) => {
                                    return (
                                        <option value={val.name} key={index}> {val.name} </option>
                                    );
                                })}
                            </select>


                            <p>Upload Logo</p>
                            <div className='uploadSection'>
                                <div className='upBtn' id={imageSelection ? "colBt2" : "colBt1"}>
                                    {imageSelection ? <AddTaskIcon /> : <AddIcon />}
                                </div>
                                <input type="file" name="file" id="file" onChange={handleImageChange} />
                                <p> {imageSelection ? "Selected" : "Select"} From Gallery</p>
                            </div>


                            <button className='btn' type='submit'>Sign-Up</button>
                        </div>
                    </form>


                </div>
            </div>
        </>
    )
}

export default Register