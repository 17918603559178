
import '../qrVerify/form/registerForm.scss'
import ClearIcon from '@mui/icons-material/Clear';
import BackupIcon from '@mui/icons-material/Backup';
import { Button, TextField } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import axios from '../../apiConfig/api';
import { useDispatch, useSelector } from 'react-redux';
import { getProductData, getPurchaseForm } from '../../actions/availWarrantyAction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Bars } from 'react-loader-spinner';
import toast from 'react-hot-toast';

import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { TimePicker } from 'antd';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Form from 'antd/es/form/Form';





const InstallationForm = () => {


    const location = useLocation();
    const reviewUrl = location.state?.reviewUrl || '';

    const [timeValue, setTimeValue] = useState("")

    // consol.log(reviewUrl, "reviewurl")

    const navigate = useNavigate();
    // const [state, setstate] = useState(false);
    const { alphanumeric } = useParams();

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm({ mode: "onTouched" });

    ////////////////// redux ////////////////////////////////
    const dispatch = useDispatch();
    const { productDetail } = useSelector(state => state.availWarrantyReducer);
    useEffect(() => {
        if (!productDetail) {
            dispatch(getProductData(alphanumeric));
        }
    }, []);

    useEffect(() => {
        if (productDetail) {
            // // consol.log(productDetail, "prodcut details")
        }
    }, [productDetail])

    const [state, setState] = useState({
        name: "",
        phone_number: "",
        address: "",
        date: "",
        timeS: "",
        remarks: ""
    });
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [timeErr, setTimeErr] = useState("")
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "name") {
            if (value) {
                setErrName("")
            }

        }
        if (name == "address") {
            if (value) {
                setErrAddress("")
            }

        }
        if (name == "phone_number") {
            if (value) {
                setErrNumber("")
            }

        }
        if (name == "date") {
            if (value) {
                setErrDate("")
            }

        }
        setState({ ...state, [name]: value });
        // setErrNumber("")
    }

    const handleChangeTimePicker = (time, timeString) => {
        if (timeString) {
            setTimeErr("")
        }
        setTimeValue(timeString)
        // // consol.log(timeString, "time String")
        setState({ ...state, timeS: timeString });
    }









    ////////////////////////////////---------------- puchase array --------------////////////////////////////





    ///////////////////////////////////// get IP Data /////////////////////////////////




    useEffect(() => {
        setTimeout(() => {
            document.getElementById("inner").style.bottom = "0px";
        }, 10);
    }, [])

    const [errNumber, setErrNumber] = useState("")
    const [errAddress, setErrAddress] = useState("")
    const [errName, setErrName] = useState("")
    const [errDate, setErrDate] = useState("")



    const validateForm = () => {

        let isValid = true;
        if (state.phone_number.length !== 10) {
            setErrNumber("Please enter 10 digit mobile number without “0” or “+91” ");

            isValid = false;
        } else {

            setErrNumber("");
        }
        if (state.name == "") {


            setErrName("Name is required !");
            isValid = false;
        } else {
            setErrName("");
        }
        if (state.address == "") {


            setErrAddress("Address is required !");
            isValid = false;
        } else {
            setErrAddress("");
        }
        if (state.date == "") {


            setErrDate("Date is required !");
            isValid = false;
        } else {
            setErrDate("");
        }

        if (timeValue == "") {

            // // consol.log(state.timeS, "time")
            setTimeErr("Time is required !");
            isValid = false;
        } else {
            setTimeErr("");
        }


        return isValid;
    };








    const onSubmit = async () => {

        const isFormValid = validateForm();

        if (!isFormValid) {
            return;
        }

        setLoader(true);

        try {
            const token = localStorage.getItem("accessToken");
            const config = { headers: { 'Content-Type': 'application/json' } };

            const { data } = await axios.post(`/customer/submit_installation_form`, {
                token: token, alphanumeric: alphanumeric,
                contact_name: state.name,
                contact_number: state.phone_number,
                contact_address: state.address,
                installation_date: state.date,
                installation_time: state.timeS,
                other_details: state.remarks
            }, config);
            // // consol.log(data, "data")
            setLoader(false)
            toast.success("Installation details submitted successfully")
            navigate(`/`)
            if (reviewUrl) {
                window.open(reviewUrl, "_blank");
            }




        } catch (error) {
            // // consol.log(error);

        }




    }




    const [loader, setLoader] = useState(false);

    const [open, setOpen] = useState(false);




    // // consol.log(state.timeS, "state")
    // // consol.log(state, "state")

    // // consol.log(timeValue, "timeValue")







    const numberInputOnWheelPreventChange = (e) => {

        e.target.blur()


        e.stopPropagation()



        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const numberInputOnKeyDown = (e) => {
        // Allow only digits (0-9), Backspace, Delete, and Tab keys
        const allowedKeys = ['Backspace', 'Delete', 'Tab'];
        const isDigit = /\d/.test(e.key);

        if (!(isDigit || allowedKeys.includes(e.key))) {
            e.preventDefault();
        }
    };






    //---------------------------------------------------- Resend otp -----------------------------//











    return (
        <>
            <div className='main'>
                <div className='registerForm' >


                    {/* ////////--------------------------- Loader ----------------------------////////// */}
                    {loader && <div className='warrantyLoader'>
                        <Bars
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>}
                    {/* ////////--------------------------- Loader ----------------------------////////// */}


                    <div id='inner'>

                        <div id="line"></div>

                        <div className='innerScroll'>

                            <div>

                                <button className='btn but1' onClick={() => navigate(`/`)}><ClearIcon /></button>

                                <div className='head'>
                                    <div>Installation Form</div>

                                </div>


                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="form-group">
                                        <label>Name <span>*</span></label>
                                        <input type="text" className="form-control" name="name" value={state.name} placeholder='Enter Your Name' onChange={handleChange} />
                                        <span>{errName}</span>
                                    </div>

                                    <div className="form-group">
                                        <label>Phone <span>*</span></label>
                                        <input type="number" className="form-control" name="phone_number" value={state.phone_number} placeholder='Enter Your Phone Number' onChange={handleChange}
                                            onWheel={numberInputOnWheelPreventChange}
                                            onKeyDown={numberInputOnKeyDown}
                                        />

                                        <div className='text-red-500' style={{ color: "red", marginTop: "3px" }}>{errNumber}</div>
                                    </div>

                                    <div className="form-group">
                                        <label>Address <span>*</span></label>
                                        <input style={{ textTransform: "none" }} type="text" className="form-control" name="address" value={state.address} placeholder='Enter Your Address' onChange={handleChange} />
                                        <span>{errAddress}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Prefered Installation Date <span>*</span></label>
                                        <input style={{ textTransform: "none" }} type="date" className="form-control" name="date" value={state.date} placeholder='Enter Your Date' onChange={handleChange} />
                                        <span>{errDate}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Prefered Installation Time <span>*</span></label>
                                        <div>


                                            <TimePicker
                                                use12Hours
                                                name='time'
                                                format="h:mm a"
                                                onChange={handleChangeTimePicker}
                                                className='form-control rounded-lg'

                                            />


                                        </div>
                                        <span className='text-red-500'>{timeErr}</span>
                                    </div>


                                    <div className="form-group">
                                        <label>Remarks </label>
                                        <input style={{ textTransform: "none" }} type="text" className="form-control" name="remarks" value={state.remarks} placeholder='Enter Your remarks' onChange={handleChange} />

                                    </div>







                                    <div className='genBox'> <Button className='generate' variant='contained' type='submit'>Submit</Button> </div>



                                </form>


                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default InstallationForm