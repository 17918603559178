import React, { useRef } from 'react'
import './qrVerify.scss'
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/onepoket.png'
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import g2 from '../../assets/g2.gif'
import g3 from '../../assets/g3.gif'
import { getProductData } from '../../actions/availWarrantyAction';
import ReactPlayer from 'react-player';


const QrVerify = () => {

    const { alphanumeric } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [auth, setauth] = useState("");

    const [productData, setProductData] = useState({
        video: [{ video: "" }],
        images: [{ image: "" }],
        avatarUrl: "",
        warranty: "",
        product_name: "",
        product_description: "",
        product_model: "",
        product_shipping_weight: "",
        additionalInfo: [{
            title: "",
            description: ""
        }],
        added_at: "",
        showManufactureDate: false
    });


    /////////////////////// redux ///////////////////////////////////////
    const { productDetail, warrantyLoading } = useSelector(state => state.availWarrantyReducer);

    useEffect(() => {
        if (!productDetail) {
            // consol.log(productDetail, "product details in useeffect1")
            dispatch(getProductData(alphanumeric));

        }
    }, []);

    useEffect(() => {

        var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


        if (productDetail) {
            // // consol.log(productDetail, "product detail")
            const date = Number(productDetail.added_at.slice(5, 7));
            const newVideoArray = productDetail?.video?.map((obj) => {
                return { ...obj, video: obj.video.replace("watch?v=", "embed/") }
            });
            // // consol.log(newVideoArray, "newvideo")


            setProductData({ ...productDetail, video: newVideoArray, added_at: `${productDetail.added_at.slice(8, 10)}, ${month[date - 1]} ${productDetail.added_at.slice(0, 4)}` });
            setauth(productDetail.warranty);


            // // consol.log(productDetail)

        }
        // else {
        //     // consol.log(alphanumeric, "alphanumeric")

        //     // consol.log(productDetail, "product details in useeffect1")
        //     dispatch(getProductData(alphanumeric));

        // }
    }, [productDetail]);

    const baseUrl = 'http://localhost:3001/registration/'; // Replace this with your actual base URL


    /////////////////////// redux ///////////////////////////////////////




    return (
        <>
            <div className='main'>
                <div className='qrVerify'>

                    {warrantyLoading ? <div className='warrantyLoader'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div> : null}

                    <div className='qrInnerVerify'>



                        <div className='companyLogo'>
                            <img src={productData.avatarUrl} alt="" />
                        </div>

                        <div className='name'> {productData.product_name} </div>

                        <div className={`indicatorBox ${auth == "availed" ? "redColor" : ""} ${auth == "unavailed" ? "greenColor" : ""}`}>
                            <div className='gifBox'>
                                {auth == "unavailed" ? <img src={g2} alt="" /> : null}
                                {auth == "availed" ? <img src={g3} alt="" /> : null}
                            </div>

                            {auth == "unavailed" ? <p className='p1'><strong>Great Choice!</strong> Register below and enjoy a seamless ONE CLICK after-sales experience!</p> : null}
                            {auth == "availed" ? <p className='p2'>This product item has already been sold and registered to another user. If you see this item on sale, most likely it is a counterfeit. Please help us protect buyers like yourself by reporting this incident.</p> : null}

                            <section>Authenticated by <strong>
                                <div className=''><img src={logo} alt="" className=''/></div>Onepoket</strong>
                            </section>

                        </div>

                        {auth == "availed" ? <div id='text'>If you have registered this product before, please <span>sign in</span> with the corresponding account.</div> : null}


                        <div className='productSection'>


                            <div className='productImages'>
                                {productData.images && productData?.images[0] && <img src={productData?.images[0]?.image} alt="" />}
                            </div>



                            <div className='productName'> {productData.product_name} </div>
                            <div className='productDetail'> {productData.product_model} </div>
                            {productData.showManufactureDate ? <div className='menufacture'><strong>Manufacture On</strong> : {productData.added_at}</div> : null}
                            <div className='additional'>


                                {productData.additionalInfo.map((val, index) => {
                                    return (
                                        <div key={index}><strong>{val.title}</strong> : {val.description}</div>
                                    );
                                })}


                            </div>
                            <div className='des'>
                                <section>Description:</section>
                                <p

                                    id="productDescription"
                                >
                                    {productData.product_description.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>

                        </div>







                        {productData.video.map((val, index) => {
                            if (val.video) {
                                // // consol.log(val.video, "video");
                                return (
                                    <div className='m-4' key={index}>
                                        <ReactPlayer
                                            url={val.video}
                                            controls={true}
                                            height={200}
                                            width={350}



                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}



                        {auth == "unavailed" ? <div className='activateBtn'><Button variant='contained' className='bt' onClick={() => navigate(`/registration/${productData.id}/${alphanumeric}/warranty_activate`)}>Activate Warranty</Button></div> : null}
                        <div className='footer'>
                            <div className='grid'>
                                <div>Powered by - </div>
                                <div className='imgDiv'>
                                    <img src={logo} alt="" />
                                    onepoket </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default QrVerify