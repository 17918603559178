import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import './product.scss'

import defaultImage from '../../assets/default.jpg'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import axios from '../../apiConfig/api'
import ChatBot from 'react-simple-chatbot';
import { message } from 'antd';

const Product = () => {

  const { alphanumeric } = useParams();


  /////////////////////////// redux ////////////////////////////

  const [productData, setProductData] = useState({
    product_name: "",
    company_name: "",
    image: defaultImage,
    created_on: "",
    year: "",
    month: "",
    helpline_email: "",
    helpline_number: "",
    is_installation_required: Number(),
    use_installation_form: Number(),
  });

  const [businessId, setBusinessId] = useState(0)
  const [statusChatBot, setStatusChatBot] = useState(0)
  const [botPublished, setIsBotPublished] = useState(0)

  const [chatbotData, setChatbotData] = useState({
    nodes: [],
    links: [],
    languages:[]
  });
  const getProductDetails = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
      const { data } = await axios.post("/customer/productDetails", { alphanumeric: alphanumeric, token }, config);

      // consol.log(data[0], "product details");
      setBusinessId(data[0].business_id)

      const date = new Date(data[0].created_on);
      let yr = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month > 9 ? month : "0" + month;
      let dt = date.getDate();
      dt = dt > 9 ? dt : "0" + dt;

      setProductData({ ...data[0], created_on: `${dt}-${month}-${yr}` });

    } catch (error) {
      // // consol.log(error);
    }
  }

  useEffect(() => {
    getProductDetails();
  }, []);

  const navigate = useNavigate()
  const [dataInstallSubmit, setDataInstallSubmit] = useState([])
  const [dataInstall, setDataInstall] = useState([])

  const getInstallationData = async () => {

    try {
      const token = localStorage.getItem("accessToken");
      const config = { headers: { 'Content-Type': 'application/json' } };

      const { data } = await axios.post(`/customer/get_installation_data`, {
        token: token, alphanumeric: alphanumeric
      }, config);
      // // consol.log(data?.form_data, "data")
      setDataInstall(data?.form_data)
      setDataInstallSubmit(data?.is_form_submitted)




    } catch (error) {
      // // consol.log(error);

    }
  }
  useEffect(() => {
    getInstallationData()
  }, [])


  // Assuming your node and link data are stored in variables named nodesData and linksData

// const generateChatbotSteps = (nodesData, linksData) => {
//   // consol.log(nodesData,linksData)
//   const steps = [];

//   // Create a mapping of node IDs to their data
//   const nodesMap = {};
//   nodesData.forEach(node => {
//     nodesMap[node.id] = node;
//   });

//   // Iterate through links to create conversation steps
//   linksData.forEach(link => {
//     const sourceNode = nodesMap[link.source.nodeId];
//     const targetNode = nodesMap[link.target.nodeId];

//     if (sourceNode && targetNode) {
//       const step = {
//         id: link.id,
//         message: sourceNode.label,
//         trigger: targetNode.id, // Use the target node ID as the trigger for the next step
//       };

//       steps.push(step);
//     }
//   });

//   // Add a final step to end the chat
//   steps.push({
//     id: 'end-chat',
//     message: 'Thanks for chatting with me!',
//     end: true,
//   });

//   return steps;
// };



// const generateChatbotSteps = (nodesData, linksData) => {
//   const steps = [];

//   // Create a mapping of node IDs to their data
//   const nodesMap = {};
//   nodesData.forEach(node => {
//     nodesMap[node.id] = node;
//   });

//   // Function to check if link nodes are of type LINK_NODE
//   const areLinkNodesOfTypeLinkNode = (nodeId) => {
//     const outgoingLinks = linksData.filter(link => link.source.nodeId === nodeId);

//     return outgoingLinks.every(link => {
//       const targetNode = nodesMap[link.target.nodeId];
//       return targetNode && targetNode.type === 'LINK_NODE';
//     });
//   };

//   // Iterate through nodes
//   nodesData.forEach(node => {
//     const nodeId = node.id;
//     const text = node.label;

//     // Check if link nodes are of type LINK_NODE
//     const areLinkNodesLinkNode = areLinkNodesOfTypeLinkNode(nodeId);

//     // Create step based on link node type
//     const step = areLinkNodesLinkNode
//       ? {
//           id: nodeId,
//           options: outgoingLinks
//             .filter(link => nodesMap[link.target.nodeId]) // Filter out invalid target nodes
//             .map(link => {
//               const targetNode = nodesMap[link.target.nodeId];

//               // Add options for LINK_NODE
//               return {
//                 value: targetNode.id,
//                 label: targetNode.data[0]?.text || 'Option', // Adjust this based on your data structure
//                 trigger: targetNode.id,
//               };
//             }),
//         }
//       : {
//           id: nodeId,
//           message: text,
//           trigger: nodeId,
//         };

//     // Add the step to the steps array
//     steps.push(step);
//   });

//   // Log steps for debugging
//   // consol.log('Generated Steps:', steps);

//   // Add a final step to end the chat
//   steps.push({
//     id: 'end-chat',
//     message: 'Thanks for chatting with me!',
//     end: true,
//   });

//   return steps;
// };


const generateChatbotSteps = (nodesData, linksData,language_data) => {

  const steps = [];
// consol.log(nodesData,linksData,language_data)
  // Create a mapping of node IDs to their data
  const nodesMap = {};
  nodesData.forEach(node => {
    nodesMap[node.id] = node;
  });
// consol.log(nodesMap)
const languageMessage = {
  id: 'language-message',
  message: 'Please select your language:',
  trigger:'language-list'
};
const lang_filterred=language_data
.filter(language => language.status === 1)
const lang_array=language_data
.filter(language => language.status === 1)
.map(language => ({
  value: language.display_name,
  label: language.display_name,
  trigger: 'node_3' + language.display_name.toString(),
}))

if(lang_array.length>1){
steps.push(languageMessage);
const languageStep = {
  id: 'language-list',

  options:lang_array}

// consol.log(languageStep)
steps.push(languageStep)
}

else {
  steps.push({
    id:'single',
    message:'Hi',
    trigger:lang_array[0].trigger
  })
}

  // Iterate through nodes
  lang_filterred.map((lang)=>{
    // if(lang.status){
    //   return (
  nodesData.forEach(node => {
    const nodeId = node.id;
    if(node.type==='START_NODE'){
// consol.log(node)
if(node.data){
steps.push(...node.data[0].map((data,index)=>{return {
  id: 'End chat'+''+data.lang,
  // message: data.text?data.text:'Thanks for Using.',
  // trigger:'Restart Chat'+data.lang
  options:[{value:'End-Chat'+node.data[1][index].lang,
label:node.data[0][index].text?node.data[0][index].text:'End Chat',
end:true
},{value:'not-resolved'+node.data[1][index].lang,
label:node.data[1][index].text?node.data[1][index].text:'My query is not resolved',
trigger:'Contact'+node.data[1][index].lang
},{value:'restart'+node.data[2][index].lang,
label:node.data[2][index].text?node.data[2][index].text:'Restart',
trigger:lang_array.length>1? 
'language-message'
:'single'

}]
}})
);
// steps.push(...node.data[2].map((data)=>{return {
//   id: data.use_case+''+data.lang,
//   message: data.text?data.text:'Want to Restart?',
//   trigger:'res-option'+data.lang
// }}));
// steps.push(...node.data[1].map((data)=>{return {
//   id: data.use_case+''+data.lang,
//   message: data.text?data.text:'Is your query Resolved?',
//   trigger:'not-resolved-option'+data.lang
// }}));

// steps.push(...lang_array.map((lang)=>{return{
//   id: 'not-resolved-option'+lang.value,

// options:[
  
// {
//   value:'yes',
//   label:'Yes',
//   // trigger:'language-message'
//   // end:true
// },{
//   value:'no',
//   label:'NO',
//   trigger:'Contact'+lang.value,
// //  end:true
// }]
// } }));
// // steps.push({
// //   id: 'Restart',
// //   message: 'Do You want to restart?',
// //  trigger:'res-option'
// // });
// steps.push(...lang_array.map((lang)=>{return{
//   id: 'res-option'+lang.value,

// options:[lang_array.length>1?
  
// {
//   value:'yes',
//   label:'Yes',
//   trigger:'language-message'
// }:{
//   value:'yes',
//   label:'Yes',
//   trigger:'single'
// },{
//   value:'no',
//   label:'NO',
//   trigger:"Not-resolved"+lang.value
// //  end:true
// }]
// } }));
}
    }

   else if(node.type==='END_NODE'){
      // consol.log(node)
      
     steps.push(...node.data?.map((data)=>{
      return{
        id:'Contact'+data.lang,
        message:data.text?data.text:node.label,
        end:true
      }
     }))
      // steps.push({
      //   id: 'Restart',
      //   message: 'Do You want to restart?',
      //  trigger:'res-option'
      // });
      
      
          }
    else{
    const text = node.data?node.data.filter((n)=>{if(n.lang===lang.display_name)
                                            return(n.text)
    })[0]?.text:node.label

    // Get outgoingLinks for the current node
    const outgoingLinks = linksData.filter(link => link.source.nodeId === nodeId);
// consol.log(outgoingLinks)
const step = {
  id: nodeId+''+lang.display_name,
message:text?text:node.label?node.label:'text'
};
      if(outgoingLinks.length==1&&nodesMap[outgoingLinks[0].target.nodeId].type==='TEXT_NODE'){
        step.message=text?text:node.label?node.label:'text'
        step.trigger=outgoingLinks[0].target.nodeId+''+lang.display_name
        steps.push(step);

      }

     else if(outgoingLinks.length==1&&nodesMap[outgoingLinks[0].target.nodeId].type==='END_NODE'){
      // consol.log(node.data)
      // step.id='end-chat'  

      step.message=text?text:node.label?node.label:'text'
      // consol.log(node.data)
      step.trigger='End chat'+lang.display_name
        steps.push(step);

      }

    // Create step based on whether there are outgoingLinks
 

    // If there are outgoingLinks, create options
    else if (outgoingLinks.length > 0) {
      // consol.log(node)
      const next_step_id=Date.now().toString()
     
      step.trigger= next_step_id
      steps.push(step);
      
      // consol.log(outgoingLinks)
      const next_step={
        id: next_step_id
      }
      next_step.options = outgoingLinks.map(link => {
        const targetNode = nodesMap[link.target.nodeId];
        const text = targetNode.data?targetNode.data.filter((n)=>{if(n.lang===lang.display_name)
          return(n.text)
})[0]?.text:targetNode.label

// consol.log(targetNode.type)
        // if(targetNode.type==='LsINK_NODE')
        // return {
        //   value: targetNode.id,
          
        //   label:text?text: targetNode.label || 'Option', // Adjust this based on your data structure
        //   trigger: targetNode.id+''+lang.display_name+'next',
        // };
        // else{
          return {
            value: targetNode.id,
            
            label:text?text: targetNode.label || 'Option', // Adjust this based on your data structure
            trigger: targetNode.id+''+lang.display_name,
          // };
        }
      });

      steps.push(next_step)
    } else {
      step.trigger = 'End chat'+lang.display_name;
      steps.push(step); // If no outgoingLinks, trigger the end-chat step
    }

    // Add the step to the steps array
   
  }})
// )}
})

  // Log steps for debugging
  // consol.log('Generated Steps:', steps);

  // Add a final step to end the chat
 

  return steps;
};


// const generateChatbotSteps = (nodesData, linksData, languageData) => {
//   const steps = [];
//   const nodesMap = {};
//   nodesData.forEach(node => {
//     nodesMap[node.id] = node;
//   });
//   // Step 1: Language Selection
//   const languageStep = {
//     id: 'language-selection',
//     message: 'Please select your language:',
//     options: languageData.map(language => ({
//       value: language.id,
//       label: language.display_name,
//       trigger: language.id.toString(), // Use language ID as the trigger for the next step
//     })),
//   };
//   steps.push(languageStep);

//   // Create a mapping of language IDs to language data
//   const languageMap = {};
//   languageData.forEach(language => {
//     languageMap[language.id.toString()] = language;
//   });

//   // Iterate through nodes
//   nodesData.forEach(node => {
//     const nodeId = node.id;
//     const text = node.label;

//     // Get outgoingLinks for the current node
//     const outgoingLinks = linksData.filter(link => link.source.nodeId === nodeId);

//     // Create step based on whether there are outgoingLinks
//     const step = {
//       id: nodeId,
//       message: text,
//     };

//     // If there are outgoingLinks, create options based on the chosen language
//     if (outgoingLinks.length > 0) {
//       const nextStepId = Date.now().toString();
//       step.trigger = nextStepId;
//       steps.push(step);

//       const nextStep = {
//         id: nextStepId,
//       };
//       nextStep.options = outgoingLinks.map(link => {
//         const targetNode = nodesMap[link.target.nodeId];
//         const languageText = targetNode.data.find(data => data.lang === languageMap[link.target.nodeId].name);

//         return {
//           value: targetNode.id,
//           label: languageText?.text || 'Option',
//           trigger: targetNode.id,
//         };
//       });

//       steps.push(nextStep);
//     } else {
//       step.trigger = 'end-chat';
//       steps.push(step); // If no outgoingLinks, trigger the end-chat step
//     }
//   });

//   // Log steps for debugging
//   // consol.log('Generated Steps:', steps);

//   // Add a final step to end the chat
//   steps.push({
//     id: 'end-chat',
//     message: 'Thanks for chatting with me!',
//     end: true,
//   });

//   return steps;
// };


// Example usage:


  const getCustomerCompanyBot = async () => {

    try {
      const token = localStorage.getItem("accessToken");
      const config = { headers: { 'Content-Type': 'application/json' } };

      const { data } = await axios.post(`/customer/company_bot`, {
        token: token, company_id: businessId
      }, config);

      if (data?.status && data?.status === 1&&data?.is_bot_published&&data?.is_bot_published===1) {
        setStatusChatBot(data?.status)
      }
      // if (data?.p && data?.status === 1) {
      //   setStatusChatBot(data?.status)
      // }

      // consol.log(data)

      // consol.log(JSON.parse(data?.temp_data?.data)); 
      const Parsed_data=JSON.parse(data?.temp_data?.data)
      Parsed_data.languages=data?.language_data
      
      setChatbotData(Parsed_data)



    } catch (error) {
      // // consol.log(error);

    }
  }
  useEffect(() => {
    if (businessId) {
      getCustomerCompanyBot();
    }
  }, [businessId]);

  
  const sendForm = () => {

    if (dataInstallSubmit === 1) {
      navigate(`/view_installation_form/${alphanumeric}`)
    } else {

      navigate(`/installation_form/${alphanumeric}`)
    }

  }


  return (
    <>
      <div className='main'>

        <div className='productPage1'>

          <Link to="/" className='btn goback'><ArrowBackIcon fontSize='large' /></Link>

          <div className='img-box'>
            <div className='imageCon'>
              <img className='image' src={productData.image} alt="" />
            </div>
          </div>


          <div className='detailArea'>

            <div className='phead'>
              <p className='title'>{productData.product_name}</p>
              <section className='brName mt-2'>{productData.company_name}</section>
            </div>
            {productData.is_installation_required === 1 && productData.use_installation_form === 1 ? (<div className='w-[85%] mx-auto'>
              <a className='underline text-blue-400 cursor-pointer' onClick={sendForm}>Installation Details</a>
            </div>) : null}

            <div className='content'>
              <div className='data'>
                <section>Registration Date</section>
              </div>
              <div className='data2'>
                <section>{productData.created_on}</section>
              </div>
            </div>

            <div className='content'>
              <div className='data'>
                <section>Warranty Period</section>
              </div>
              <div className='data2'>
                {productData?.year > 0 || productData?.month > 0 ? (
                  <>
                    {productData?.year > 0 && (
                      <>
                        {productData.year > 1 ? productData.year + ' years ' : productData.year + ' year '}
                      </>
                    )}
                    {productData?.month > 0 && (
                      <>
                        {productData.month > 1 ? productData.month + ' months ' : productData.month + ' month '}
                      </>
                    )}
                  </>
                ) : (
                  'N/A'
                )}
              </div>

            </div>

            <div className='w-[85%] mx-auto mt-2'>

              {productData?.product_desc_for_customer}

            </div>



            <div className='help'>
              <div>Help & Support</div>
              <section><PhoneIcon className='icon' /> {productData.helpline_number} </section>
              <section><MailOutlineIcon className='icon' /> {productData.helpline_email} </section>
            </div>


          </div>

          {statusChatBot === 1 ? (<ChatBot
          // cache={true}
            steps={generateChatbotSteps(chatbotData.nodes, chatbotData.links,chatbotData.languages)}
            floating={true}
          />) : null}
        </div>
      </div>
    </>
  )
}

export default Product