import { useNavigate } from 'react-router-dom'
import React from 'react'
import './productcard.scss'

import defaultImage from '../../assets/default.jpg'


const Productcard = (props) => {

  const navigate = useNavigate();
  // // consol.log(props?.data , "props")

  const sendData = () => {
    navigate(`/product/${props.data.alphanumeric}`);
  }

  const image =
    props?.data?.images && props.data.images.length > 0
      ? props.data.images[0].image
      : defaultImage;
  // consol.log(image, "image")

  // // consol.log(image , "image")

  return (
    <>
      <div className='productCard' onClick={sendData}>
        <div className='imageDiv'>
          <img src={image} alt="..." />
        </div>
        <section className='productName'>{props.data.product_name}</section>
        <div className='brandName'>{props.data.company_name}</div>
      </div>
    </>
  )
}

export default Productcard