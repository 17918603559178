
import '../qrVerify/form/registerForm.scss'
import ClearIcon from '@mui/icons-material/Clear';
import BackupIcon from '@mui/icons-material/Backup';
import { Button, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import axios from '../../apiConfig/api';
import { useDispatch, useSelector } from 'react-redux';
import { getProductData, getPurchaseForm } from '../../actions/availWarrantyAction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Bars } from 'react-loader-spinner';
import toast from 'react-hot-toast';

import CallIcon from '@material-ui/icons/Call';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { TimePicker } from 'antd';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';





const ViewInstallationForm = () => {




    const navigate = useNavigate();
    // const [state, setstate] = useState(false);
    const { alphanumeric } = useParams();



    ////////////////// redux ////////////////////////////////
    const dispatch = useDispatch();


    const [state, setState] = useState({
        contact_name: "",
        contact_number: "",
        contact_address: "",
        installation_date: "",
        installation_time: "",
        other_details: ""
    });













    useEffect(() => {
        setTimeout(() => {
            document.getElementById("inner").style.bottom = "0px";
        }, 10);
    }, [])

    const [errNumber, setErrNumber] = useState("")











    const getInstallationData = async () => {

        try {
            const token = localStorage.getItem("accessToken");
            const config = { headers: { 'Content-Type': 'application/json' } };
            setLoader(true)
            const { data } = await axios.post(`/customer/get_installation_data`, {
                token: token, alphanumeric: alphanumeric
            }, config);
            setLoader(false)
            // // consol.log(data?.form_data, "data")
            //   setDataInstall(data?.form_data)
            //   setDataInstallSubmit(data?.is_form_submitted)
            setState(data?.form_data)




        } catch (error) {
            // // consol.log(error);

        }
    }
    useEffect(() => {
        getInstallationData()
    }, [])




    const [loader, setLoader] = useState(false);

    const [open, setOpen] = useState(false);

    const handleChangeTimePicker = (time, timeString) => {
        setState({ ...state, time: timeString });

    };

    // // consol.log(state, "state")










    //---------------------------------------------------- Resend otp -----------------------------//











    return (
        <>
            <div className='main'>
                <div className='registerForm' >


                    {/* ////////--------------------------- Loader ----------------------------////////// */}
                    {loader && <div className='warrantyLoader'>
                        <Bars
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>}
                    {/* ////////--------------------------- Loader ----------------------------////////// */}


                    <div id='inner'>

                        <div id="line"></div>

                        <div className='innerScroll'>

                            <div>

                                <button className='btn but1 text-black' onClick={() => navigate(`/product/${alphanumeric}`)}><ClearIcon /></button>

                                <div className='head'>
                                    <div> Installation Details Submitted</div>

                                </div>


                                <form >

                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Name </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                        <div className='w-[70%]' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1 pt-1'>{state.contact_name}</div>
                                        </div>

                                    </div>

                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Phone </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                        <div className='w-[70%]' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1 pt-1'>{state.contact_number}</div>
                                        </div>

                                    </div>

                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Address </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                        <div className='w-[70%] ' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1 pt-1'>{state.contact_address}</div>
                                        </div>

                                    </div>


                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Prefered Installation Date </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                        <div className='w-[70%]' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1  pt-1'>{String(state.installation_date).slice(0, 10)}</div>
                                        </div>

                                    </div>
                                    {/* <div className="form-group flex justify-between">
                                        <label>Prefered Installation Time </label>
                                        <div>{state.installation_time}</div>

                                    </div> */}
                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Prefered Installation Time </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                        <div className='w-[70%]' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1  pt-1'>{state.installation_time}</div>
                                        </div>

                                    </div>


                                    <div className="form-group flex justify-between ">
                                        <label className='w-[30%] pt-[5px]'>Remarks </label>
                                        {/* <input type="text" className="form-control" name="name" value={state.contact_name} placeholder='Enter Your Name' readOnly/> */}
                                       {state.other_details.length>0 ? ( <div className='w-[70%] ' style={{ display: 'flex' }}>
                                            <div className='text-[20px]'>:</div>
                                            <div style={{ fontSize: '16px' }} className='mx-1  pt-1'>{state.other_details}</div>
                                        </div>) : null}

                                    </div>











                                </form>


                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ViewInstallationForm