import React from 'react'
import './editprofile.scss'
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';


const EditProfile = () => {

    const [edit, setEdit] = useState(false);
    const [save, setSave] = useState(false);

    const navigate = useNavigate();

    const verifyData = () => {
        navigate("/verify");
    }

    const saveData = () => {
        setSave(true);
        setEdit(true);
    }


    ////////////////////////////--------------------- redux ----------------------------///////////////////////
    const { user } = useSelector(state => state.userReducer);
    const [profileData, setProfileData] = useState({
        shop_name: "",
        owner_name: "",
        phone_number: "",
        email: "",
    });

    useEffect(() => {
        if (user) {
            setProfileData(user);
        }
    }, [user]);



    return (
        <>
            <div className='main'>
                <div className='editPro'>

                    <div className='header'>
                        <Link to="/profile" className='btn back'><ArrowBackIcon fontSize='large' /></Link>
                        Profile {!edit ? <span onClick={verifyData}><EditIcon /></span> : null}
                    </div>

                    <div className='editbox1'>

                        <div className='data'>
                            <label>Shop Name</label>
                            {!edit ? <div> {profileData.shop_name} </div> :
                                <input type="text" value="A One Electronics" />}
                        </div>

                        <div className='data'>
                            <label>Owner Name</label>
                            {!edit ? <div> {profileData.owner_name} </div> :
                                <input type="text" value="DevRaaj Kumar" />}

                        </div>

                        <div className='data'>
                            <label>Number</label>
                            {!edit ? <div>+91 {profileData.phone_number} </div> :
                                <input type="text" value="+91 123 456 7890" />}

                        </div>

                        <div className='data'>
                            <label>Gmail</label>
                            {!edit ? <div> {profileData.email} </div> :
                                <input type="text" value="a1electronic@gmail.com" />}

                        </div>

                        {save ? <div className='save'> <Button variant='contained' className='btn1' onClick={saveData}>Save Changes</Button> </div> : null}

                    </div>


                </div>
            </div>
        </>
    )
}

export default EditProfile