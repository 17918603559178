import React, { useEffect, useState } from 'react'
import './home.scss'
import SearchIcon from '@material-ui/icons/Search';
import Productcard from '../../components/productcard/Productcard';
// import Marketcard from '../../components/marketcard/Marketcard';

import demo from '../../assets/demo.jpg';
import Navbar from '../../components/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerProducts } from '../../actions/customerProductsActions';
import MenuIcon from '@mui/icons-material/Menu';


const Home = () => {


  const openNavbar = () => {
    const l = document.getElementById('con');
    l.style.display = 'block';

    const bar = document.getElementById('slide');
    bar.style.left = '0px';
  }


  /////////////////////////////// ------------- authentication --------------- ///////////////////
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate('/login');
      }
    }, 500);
  }, []);


  //////////////////////////////////// products //////////////////////////////////////
  const [ProductArray, setProductArray] = useState([]);
  const { customerProductList } = useSelector(state => state.customerProductsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProducts());
  }, []);

  useEffect(() => {
    if (customerProductList) {
      // // consol.log(customerProductList);
      setProductArray(customerProductList);
    }
  }, [customerProductList]);



  return (
    <>
      <div className='main'>
        <div className='home'>

          <div className='homeGrid1'>
            <Navbar />

            <div className='navBar'>
              <span onClick={openNavbar}><MenuIcon className='menu' /></span>  Your Purchase
            </div>

          </div>

          <div className='homeGrid2'>

            <div className='productContainer'>

              {
                [...ProductArray].reverse().map((val, index) => {
                  return (
                      <Productcard key={index} data={val}/>
                  );
                })
              }

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Home