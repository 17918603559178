import { combineReducers } from "redux";
import availWarrantyReducer from "./availWarrantyReducer";
import customerProductsReducer from "./customerProductsReducer";
import userReducer from "./userReducer";
import puchaseFromReducer from "./puchaseFromReducer";

const rootReducer = combineReducers({
    userReducer: userReducer,
    availWarrantyReducer: availWarrantyReducer,
    customerProductsReducer: customerProductsReducer,
    puchaseFromReducer: puchaseFromReducer,
});

export default rootReducer;