import { AVAIL_FAIL, AVAIL_REQUEST, AVAIL_SUCCESS, PURCHASE_FROM_SUCCESS } from "../constants/availWarrantyConst";
import axios from "../apiConfig/api";

export const getProductData = (alphanumeric) => async (dispatch) => {
    try {
        dispatch({ type: AVAIL_REQUEST });
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
        const { data } = await axios.post("/isWarrantyAvailed", { alphanumeric: alphanumeric }, config);
        dispatch({ type: AVAIL_SUCCESS, payload: data });
    } catch (error) {
        // // consol.log(error);
        dispatch({ type: AVAIL_FAIL });
    }
}

export const getPurchaseForm = (alphanumeric) => async (dispatch) => {
    try {
        const { data } = await axios.get(`/product_purchase_options?alphanumeric=${alphanumeric}`);
        dispatch({ type: PURCHASE_FROM_SUCCESS, payload: data });
    } catch (error) {
        // // consol.log(error);
    }
}