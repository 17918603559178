import { REQUEST_CUSTOMER_PRODUCTS, SUCCESS_CUSTOMER_PRODUCTS } from "../constants/userConstants";

const customerProductsReducer = (state = { customerProductList: null }, action) => {
    switch (action.type) {
        case REQUEST_CUSTOMER_PRODUCTS:
            return {
                ...state,
                customerProductList: []
            }

        case SUCCESS_CUSTOMER_PRODUCTS:
            return {
                ...state,
                customerProductList: action.payload
            }

        default:
            return {
                ...state
            }
    }
}

export default customerProductsReducer;